<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <b-table
      id="refListTable"
      ref="refListTable"
      no-provider-paging
      no-provider-filtering
      :fields="tableColumns"
      :items="itemLists"
      responsive
      primary-key="id"
      show-empty
      :busy="isBusy"
      :empty-text="$t('globalSingular.noMatching')"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <template #table-busy>
        <div class="text-center text-primary my-2 d-flex flex-column justify-content-center">
          <div class="text mb-1">
            <b-spinner class="align-middle" />
          </div>
          <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
        </div>
      </template>

      <template #head()="{ field }">
        <table-search-column
          :field="field"
          :fields="tableColumns"
          :focused-field="focusedSearchField"
          @set-focused-field="(value) => focusedSearchField = value"
          @search-data="(params) => searchData(params)"
        />
      </template>

      <!-- Column: transaction_code -->
      <template #cell(transaction_code)="{ item }">
        <b-link
          v-if="item.module_approval.transactionType.code && item.module_approval.transactionType.code !== 'RC'"
          :to="{
            name: `apps-transactions-${getPathDetailModule(item.module_approval.transactionType.code)}-detail`,
            params: { id: item.transaction_id, fromApproval: true, approvalId: item.id }
          }"
          class="font-weight-bolder"
        >
          <span class="font-weight-bolder d-block text-nowrap">
            <u>{{ item.transaction_code }}</u>
          </span>
        </b-link>
        <span v-else class="font-weight-bolder d-block text-nowrap">
          {{ item.transaction_code }}
        </span>
      </template>

      <!-- Column: Normal Balance -->
      <template #cell(amount)="{ item }">
        <span class="font-weight-bold text-nowrap">
          {{ formatCurrency(item.amount) }}
        </span>
      </template>

      <!-- Column: Audit  -->
      <template #cell(audit)="{ item }">
        <span class="font-weight-bolder d-block text-nowrap">
          {{ item.updated_process }}  By {{ getUserName(item.getUser) }}
        </span>
        <small class="font-italic">{{ new Date(item.updated_at).toDateString() }}</small>
      </template>

      <template #cell(actions)="{ item }">
        <div class="d-flex">
          <b-button
            class="mr-1"
            variant="outline-danger"
            size="sm"
            @click="handleModuleReject(item)"
          >
            <!-- <feather-icon
              icon="XIcon"
            /> -->
            {{ $t('globalActions.reject') }}
          </b-button>
          
          <b-button
            class="mr-1"
            variant="outline-primary"
            size="sm"
            @click="handleModuleRevision(item)"
          >
            <!-- <feather-icon
              icon="XIcon"
            /> -->
            {{ $t('globalActions.revision') }}
          </b-button>

          <b-button
            variant="outline-success"
            size="sm"
            @click="handleModuleApprove(item)"
          >
            <!-- <feather-icon
              icon="CheckIcon"
            /> -->
            {{ $t('globalActions.approve') }}
          </b-button>                  
        </div>
      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('globalSingular.showing') }} {{ dataMeta.from }} {{ $t('globalSingular.to') }} {{ dataMeta.to }} {{ $t('globalSingular.of') }} {{ dataMeta.of }} {{ $t('globalSingular.entries') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalList"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            aria-controls="refListTable"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

  </b-card>
</template>


<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BLink,
  BPagination,
  BSpinner
} from 'bootstrap-vue'
import TableSearchColumn from "@/components/TableSearchColumn.vue"

import { ref, onMounted } from '@vue/composition-api'
// import useList from './useList'
import { formatCurrency } from '@/utils/formatter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useListTablePage from '@/comp-functions/useListTablePage'
import useHttp from '@/comp-functions/useHttp'
import useLocalization from '@/comp-functions/utils/useLocalization'
import moment from 'moment'


export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BLink,
    BPagination,
    BSpinner,
    TableSearchColumn
  },


  setup () {
    const { $t }   = useLocalization()
    const { $put } = useHttp()

    const {
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      focusedSearchField,
      fetchLists,
      loadPage,
      changePerPage,
      searchData,
    } = useListTablePage({ url: 'transaction/module-approval?is_approved=false' })

    // Table Handlers
    const tableColumns = ref([
      {
        key: 'module_approval.transactionType.remark_eng',
        label: $t('globalSingular.module'),
        thClass: 'px-1',
        tdClass: 'px-1',
        sortable: true,
        search: {
          type: 'text',
          value: '',
          key: 'module'
        }
      },
      {
        key: 'created_at',
        label: $t('globalSingular.date'),
        thClass: 'px-1',
        tdClass: 'px-1',
        formatter: (value) => value ? moment(value).format('DD-MM-YYYY HH:mm:ss') : '-',
        sortable: true,
        search: { type: 'date', value: '' }
      },
      {
        key: 'transaction_code',
        label: $t('globalSingular.codeTransactions'),
        thClass: 'px-1',
        tdClass: 'px-1',
        sortable: true,
        search: { type: 'text', value: '' }
      },
      {
        key: 'user_approved',
        label: $t('apps.transactions.moduleApproval.singular.approvedUser'),
        formatter: (value) => value ? value.user_name : '-',
        thClass: 'px-1',
        tdClass: 'px-1',
        // search: {
        //   type: 'text',
        //   value: '',
        //   key: 'user_approved_name'
        // }
      },
      {
        key: 'description',
        label: $t('globalSingular.description'),
        formatter: (value) => value || '-',
        thClass: 'px-1',
        tdClass: 'px-1',
        search: { type: 'text', value: '' }
      },
      {
        key: 'amount',
        label: $t('globalSingular.amount'),
        formatter: (value) => value || 0,
        thClass: 'px-1',
        tdClass: 'px-1',
        search: { type: 'number', value: 0 }
      },
      {
        key: 'audit',
        label: $t('globalSingular.audit'),
        thClass: 'px-1',
        tdClass: 'px-1',
        search: { type: 'text', value: '' }
      },
      {
        key: 'actions',
        label: $t('globalSingular.actions'),
        thClass: 'px-1',
        tdClass: 'px-1',
      }
    ]) 

    const setPadding = (row) => {
      return `padding-left: ${(row.account_level - 1) * 30}px;`
    }

    onMounted(() => {
      changePerPage(30)
    })

    return {
      formatCurrency,
      $put,
      setPadding,
      tableColumns,
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      focusedSearchField,
      fetchLists,
      loadPage,
      changePerPage,
      searchData
    }
  },


  methods: {
    getPathDetailModule(transactionType) {
      return transactionType === 'PR' ? 'purchase-request'
        : transactionType === 'PO' ? 'purchase-order'
        : transactionType === 'PV' ? 'purchase-payment-voucher'
        : ''
    },

    getUserName(user = null) {
      return user ? user.user_name : '-'
    },

    async handleModuleApprove (item) {
      this.$swal({
        title: 'Approve this Purchase Request ?',
        text: 'You can\'t undo this actions',
        icon: 'warning',
        input: 'textarea',
        inputLabel: 'Reason',
        inputPlaceholder: 'Your reason',
        inputAttributes: {
          'aria-label': 'Your reason'
        },
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-flat-warning'
        }
      })
        .then(({value: reason}) => {
          if (reason) {
            this.$put({
              url: `transaction/module-approval/${item.id}`,
              data: {
                is_approved: true,
                reason
              }
            })
              .then(() => {
                this.fetchLists()
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Success',
                    icon: 'BellIcon',
                    variant: 'success',
                    text: 'Successfully approved data'
                  }
                })
              })
            // router.push({ name: 'apps-transactions-module-approval-list' })
          }
        })
    },

    async handleModuleReject (item) {
      this.$swal({
        title: 'Reject this Purchase Request ?',
        text: 'You can\'t undo this actions',
        icon: 'warning',
        input: 'textarea',
        inputLabel: 'Reason',
        inputPlaceholder: 'Your reason',
        inputAttributes: {
          'aria-label': 'Your reason'
        },
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-flat-warning'
        }
      })
        .then(({value: reason}) => {
          if (reason) {
            this.$put({
              url: `transaction/module-approval/${item.id}`,
              data: {
                is_approved: false,
                reason
              }
            })
              .then(() => {
                this.fetchLists()
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Success',
                    icon: 'BellIcon',
                    variant: 'success',
                    text: 'Successfully reject data'
                  }
                })
              })
            // router.push({ name: 'apps-transactions-module-approval-list' })
          }
        })
    },

    async handleModuleRevision (item) {
      this.$swal({
        title: 'Revision this Purchase Request ?',
        text: 'This will give user permission to revision the data',
        icon: 'warning',
        input: 'textarea',
        inputLabel: 'What\'s need to be correct',
        inputPlaceholder: 'Your correction',
        inputAttributes: {
          'aria-label': 'Your reason'
        },
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-flat-warning'
        }
      })
        .then(({value: reason}) => {
          if (reason) {
            this.$put({
              url: `transaction/module-approval/${item.id}`,
              data: {
                is_approved: false,
                reason
              }
            })
              .then(() => {
                this.fetchLists()
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Success',
                    icon: 'BellIcon',
                    variant: 'success',
                    text: 'Successfully reject data'
                  }
                })
              })
            // router.push({ name: 'apps-transactions-module-approval-list' })
          }
        })
    }
  }
}
</script>
